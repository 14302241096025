@charset "utf-8";

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/eot/NotoSansKR-Light-Hestia.eot');
  src:
    url('./assets/fonts/woff/NotoSansKR-Light-Hestia.woff') format('woff'),
    url('./assets/fonts/eot/NotoSansKR-Light-Hestia.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/eot/NotoSansKR-Regular-Hestia.eot');
  src:
    url('./assets/fonts/woff/NotoSansKR-Regular-Hestia.woff') format('woff'),
    url('./assets/fonts/eot/NotoSansKR-Regular-Hestia.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/eot/NotoSansKR-Medium-Hestia.eot');
  src:
    url('./assets/fonts/woff/NotoSansKR-Medium-Hestia.woff') format('woff'),
    url('./assets/fonts/eot/NotoSansKR-Medium-Hestia.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/eot/NotoSansKR-Bold-Hestia.eot');
  src:
    url('./assets/fonts/woff/NotoSansKR-Bold-Hestia.woff') format('woff'),
    url('./assets/fonts/eot/NotoSansKR-Bold-Hestia.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/eot/Pretendard-Bold.eot');
  src:
    url('./assets/fonts/woff/Pretendard-Bold.woff') format('woff'),
    url('./assets/fonts/eot/Pretendard-Bold.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/eot/Pretendard-SemiBold.eot');
  src:
    url('./assets/fonts/woff/Pretendard-SemiBold.woff') format('woff'),
    url('./assets/fonts/eot/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/eot/Pretendard-Medium.eot');
  src:
    url('./assets/fonts/woff/Pretendard-Medium.woff') format('woff'),
    url('./assets/fonts/eot/Pretendard-Medium.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/eot/Pretendard-Regular.eot');
  src:
    url('./assets/fonts/woff/Pretendard-Regular.woff') format('woff'),
    url('./assets/fonts/eot/Pretendard-Regular.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/eot/Pretendard-Light.eot');
  src:
    url('./assets/fonts/woff/Pretendard-Light.woff') format('woff'),
    url('./assets/fonts/eot/Pretendard-Light.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/eot/NanumGothicBold.eot');
  src:
    url('./assets/fonts/woff/NanumGothicBold.woff') format('woff'),
    url('./assets/fonts/eot/NanumGothicBold.eot?#iefix') format('embedded-opentype');
}

/* reset */
html,
body,
div,
span,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
button,
abbr,
address,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
footer,
header,
section,
summary {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* HTML5 display-role reset for older browsers */
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

address {
  font-style: normal;
}

button {
  display: inline-block;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input,
button,
a,
select,
option {
  font-size: inherit;
}

input[type='checkbox'] {
  border: none !important;
}

/* for lte IE10 */
input[type='radio'] {
  border: none !important;
}

/* for lte IE10 */
input[type='text']::-ms-clear {
  display: none;
}

caption,
hr {
  display: none;
}

pre {
  word-break: break-all;
  white-space: pre-line;
}

li {
  list-style: none;
}

input,
select {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* common */
body,
html {
  width: 100%;
  min-height: 100%;
  font-family: 'Pretendard', 'Noto sans', '맑은 고딕', 'Malgun Gothic', Dotum, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #001a21;
  //   letter-spacing: -0.045em !important;
  letter-spacing: -0.16px !important;
  background: #fff;
}

* {
  font-family: 'Pretendard';
}

.clearFix:after {
  content: '';
  display: block;
  clear: both;
}

.hidden {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.txt-center {
  text-align: center;
}

.pos-r {
  position: relative;
}

.va-t {
  vertical-align: top;
}

b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: normal;
}

#mapArea {
  border-radius: 20px;
  overflow: hidden;

  &.webView {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 8px;
  }
}

/* 스크롤테마 */
.cScroll {
  overflow: auto;
  //  overflow-y: auto;
  //  overflow-x: hidden;
  //   scrollbar-width: thin;
  //   scrollbar-color: #1c2431 #eee;
  //   scrollbar-arrow-color: #fff;
  //   scrollbar-3dlight-color: #fff;
  //   scrollbar-darkshadow-color: #fff;
  //   scrollbar-face-color: #1c2431;
  //   scrollbar-hightlight-color: #fff;
  //   scrollbar-shadow-color: #fff;
  //   scrollbar-track-color: #fff;
  //   scrollbar-base-color: #efefef;
  //   padding-right: 8px;
  position: relative;
  //스크롤바 두께
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  //위 아래버튼
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 스크롤 뒷배경색
  &::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 10px;
  }

  // 스크롤바
  &::-webkit-scrollbar-thumb {
    background: #a7aeab;
    border-radius: 10px;
  }

  // 스크롤바 마우스오버 색
  &::-webkit-scrollbar-thumb:hover {
    background: #b6b6b6;
  }

  // 스크롤바 누르고있을 때 색
  &::-webkit-scrollbar-thumb:active {
    background: #808080;
  }
}

#root {
  height: 100%;
}

#wrap {
  width: 100%;
  height: 100%;
}

#container {
  width: 1366px;
  height: calc(100% - 40px);
  min-height: 740px;
  display: flex;
  margin: 0 auto;
  padding-top: 40px;
}

#content {
  background: #fff;
  flex: 1;
  border-radius: 0 34px 0 0;
  overflow: auto;
  padding: 32px 30px 52px 0;

  > .inner {
    overflow-y: scroll;
    height: calc(100% - 20px);
    padding: 20px 35px 0 71px;
  }
}

.ol-dragbox {
  background-color: rgba(215, 215, 215, 0.331);
  border: 2px solid #0076ff;
}

.ol-zoom {
  &.ol-control {
    width: fit-content;
    height: fit-content;
    position: fixed;
    //  top: 50px !important;
    top: unset;
    //  left: unset;
    left: 20px;
    bottom: 40px;
    > button {
      width: 30px;
      height: 30px;
    }
  }
  //   position: absolute;
  //   right: 40px;
  //   bottom: 20px;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2px;
  //   border-radius: 2px;
  //   overflow: hidden;

  //   > button {
  //     width: 36px;
  //     height: 36px;
  //     font-size: 0;
  //     border-radius: 2px;

  //     &.ol-zoom-in {
  //       background: #fff url('assets/images/ico-zoom-in.svg') no-repeat center;
  //     }

  //     &.ol-zoom-out {
  //       background: #fff url('assets/images/ico-zoom-out.svg') no-repeat center;
  //     }

  //     &:not(:last-child) {
  //       border-bottom: 1px solid #ccc;
  //     }
  //   }
}

.ol-full-screen {
  position: absolute;
  right: 10px;
  top: 10px;

  > button {
    width: 36px;
    height: 36px;
    color: #fff;
    font-size: 0;
    border: 1px solid #ccc;
    border-radius: 2px;

    &.ol-full-screen-false {
      background: #fff url('assets/images/ico-map-full.svg') no-repeat center;
    }

    &.ol-full-screen-true {
      background: #fff url('assets/images/ico-map-origin.svg') no-repeat center;
    }
  }
}
.ol-attribution {
  display: none;
}
.ol-scale-line {
  //   display: none;
}
.ol-rotate {
  display: none;
}

.recharts-layer.recharts-radial-bar-sectors {
  path:first-child {
    display: none;
  }
}

.recharts-layer.recharts-radial-bar-background {
  > .recharts-sector.recharts-radial-bar-background-sector:first-child {
    display: none;
  }
}

.ico_pop_close_light {
  fill: red !important;
}

// measure
.map {
  width: 100%;
  height: 400px;
}
.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  padding: 20px 24px;
  background-color: #fff;
  opacity: 1;
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  color: #272727;
  border: 1px solid #ccc;
  font-size: 16px;
  font-weight: 300;
  .rowFlex {
    display: flex;
    align-items: center;
    .label {
      margin-right: 20px;
    }
    .value {
      color: #f02525;
    }
  }
  .btnRemove {
    width: 100%;
    height: 36px;
    border: 1px solid #a7a7a7;
    margin-top: 16px;
    color: #a7a7a7;
  }
}
.ol-tooltip-measure:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-zoom {
  display: none;
}
.ol-scale-line{
  display: none;
}